<template>
	<section class="app-ecommerce-details">

		<b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">

			<!-- Alert: No item found -->
			<b-alert variant="danger" :show="product === undefined">
				<h4 class="alert-heading">
					Error fetching product data
				</h4>
				<div class="alert-body">
					No item found with this item slug. Check
					<b-link class="alert-link" :to="{ name: 'apps-e-commerce-shop' }">
						Shop
					</b-link>
					for other items.
				</div>
			</b-alert>

			<b-tabs>
				<b-tab active title="Detalle">
					<!-- Content -->
					<b-card v-if="product" no-body>
						<b-card-body>
							<b-row>

								<!-- Left: Product Image Container -->
								<b-col cols="12" md="4" class="d-flex align-items-center justify-content-center mb-2_ _mb-md-0">
									<div v-if="isEdit">
										<b-form-group label="Nueva Imágen" label-for="file-small" label-cols-sm="2"
											label-size="sm">
											<b-form-file v-model="imageArticle" id="file-small" size="sm"
												accept="image/*" @change="onFileChange" />
										</b-form-group>

										<span v-if="product.image_Exists" class="text-danger">
											* La imagen se va reemplazar, porque ya existe una imagen cargada
										</span>
										<hr>

										<b-button :disabled="loading" size="sm" v-if="isEdit" :block="isMobile"
											v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
											class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0" @click="uploadImage">
											<feather-icon icon="SaveIcon" class="mr-50" />
											<span>Guardar</span>
										</b-button>

										<b-button :disabled="loading" size="sm" v-if="isEdit" :block="isMobile"
											v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-secondary"
											class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0" @click="isEdit = false">
											<feather-icon icon="SlashIcon" class="mr-50" />
											<span> Cancelar </span>
										</b-button>
									</div>
									<div v-if="!isEdit" class="d-flex align-items-center justify-content-center">
										<b-img v-if="product.image_Exists" :src="convertirABase64(product.imagen)"
											:alt="`Image of ${product.name}`" class="product-img_" height="200" />
										<b-img v-else :src="logo_1" :alt="`Image of ${product.name}`"
											class="product-img_" height="200" />
									</div>

									
								</b-col>

								<!-- Right: Product Details -->
								<b-col v-if="!isEdit" cols="12" md="8">

									<!-- Product Name -->
									<h4>{{ product.name }}</h4>

									<!-- Product Brand -->
									<b-card-text class="item-company_ _mb-0">
										<!-- <strong>Clave Principal: </strong> {{ product.clabe }}<br> -->
										<!-- <strong>Código Barras: </strong> {{ product.code_bar }}<br> -->
										<strong>Unidad Venta: </strong> {{ product.unit }}
										<h6>{{ product.isAvailable ? '' : 'No' }} Disponible -
											<span :class="`text-${product.isAvailable ? 'success' : 'danger'}`">En
												stock</span>
										</h6>
									</b-card-text>
									<hr>

									<!-- Avability -->
									<b-card-text>
										<b-row class="match-height">
											<b-col v-if="product.isAvailable" cols="12" md="6" class="_match-height">
												<h6><span>Almacenes</span></h6>
												<b-list-group>
													<b-list-group-item v-for="(existencia, idxE) in product.existencias"
														:key="idxE"
														class="d-flex justify-content-between align-items-center">
														<small>{{ existencia.almacen }}</small>
														<b-badge variant="primary" pill class="badge-round">
															{{ existencia.existencia }}
														</b-badge>
													</b-list-group-item>
												</b-list-group>
											</b-col>
											<b-col v-if="product.precios" cols="12" md="6" class="_match-height">
												<h6><span>Precios</span></h6>
												<b-list-group>
													<b-list-group-item v-for="(price, idxE) in product.precios"
														:key="idxE"
														class="d-flex justify-content-between align-items-center">
														<small>{{ price.nombre_Precio }}</small>
														<b-badge variant="success" pill class="badge-round">
															{{ formatCurrency(price.precio_Con_Impto) }}
														</b-badge>
													</b-list-group-item>
												</b-list-group>
											</b-col>

											<b-col v-if="product.clabes" cols="12" md="6" class="mt-1 _match-height">
												<h6><span>Claves Articulos</span></h6>
												<b-list-group>
													<b-list-group-item v-for="(clabe, idxE) in product.clabes"
														:key="idxE"
														class="d-flex justify-content-between align-items-center">
														<small>{{ clabe.rol_Clave }}</small>
														<b-badge variant="dark" pill class="badge-round">
															{{ clabe.clave_Articulo }}
														</b-badge>
													</b-list-group-item>
												</b-list-group>
											</b-col>

											<b-col v-if="product.niveles" cols="12" md="6" class="mt-1 _match-height">
												<h6><span>Niveles de Inventario</span></h6>
												<b-list-group>
													<b-list-group-item v-for="(nivel, idxE) in product.niveles"
														:key="idxE"
														class="d-flex justify-content-between align-items-center">
														<small>{{ nivel.almacen }}</small>
														<small><strong>MIN:</strong> {{ nivel.inventario_Minimo
															}}</small>
														<small><strong>PR:</strong> {{ nivel.punto_Reorden }}</small>
														<small><strong>MAX:</strong> {{ nivel.inventario_Maximo
															}}</small>
													</b-list-group-item>
												</b-list-group>
											</b-col>
										</b-row>
									</b-card-text>

									<hr>

									<div class="d-flex flex-column flex-sm-row pt-1_">
										<b-button v-if="!isEdit && canEdit" size="sm"
											v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning"
											class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0" @click="isEdit = true">
											<feather-icon icon="EditIcon" class="mr-50" />
											<span>Editar Imagen</span>
										</b-button>

										<b-button v-if="!isEdit && canEdit" size="sm"
											@click="requestProduct(product)"
											v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
											class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0">
											<feather-icon icon="ShoppingCartIcon" class="mr-50" />
											<span>Solicitar</span>
										</b-button>

										<b-button v-if="!isEdit" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
											variant="outline-secondary" class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
											@click="$router.push({name: 'apps-e-commerce-shop'})">
											<feather-icon icon="CornerUpLeftIcon" class="mr-50" />
											<span> Regresar </span>
										</b-button>
									</div>
								</b-col>
							</b-row>
						</b-card-body>

						<!-- Static Content -->
						<!-- <e-commerce-product-details-item-features /> -->

						<!-- Static Content -->
						<!-- Slider: Related Products -->
						<!-- <e-commerce-product-details-related-products /> -->
					</b-card>
				</b-tab>
				<b-tab v-if="product.storable && canSave" title="Inventario">
					<b-form>
						<b-card no-body  class="invoice-preview-card">
							<b-card-body>
								<b-row>
									<b-col cols="12" md="4">
										<b-form-group label="Almacén" label-for="warehouse">
											<v-select v-model="payload.warehouseId" :options="wareHouses" label="nombre"
												:reduce="option => option.almacen_Id" placeholder="Selecciona un almacen"
												class="select-size-sm" :clearable="false" />
										</b-form-group>
									</b-col>
								</b-row>
							</b-card-body>

							<!-- Items Section -->
							<b-card-body v-if="payload.warehouseId" class="invoice-padding form-item-section">
								<div ref="form" class="repeater-form">
									<b-row v-for="(item, index) in payload.items" :key="index" ref="row" class="pb-2">
										
										<b-col cols="12" md="6">
											<b-form-group label="Articulo" label-for="article">
												<b-form-input size="sm" readonly v-model="item.article" />
											</b-form-group>
										</b-col>

										<b-col cols="12" md="3">
											<b-form-group label="Clave Articulo" label-for="key">
												<b-form-input size="sm" readonly v-model="item.key" />
											</b-form-group>
										</b-col>

										<b-col cols="12" md="3">
											<b-form-group label="Cantidad" label-for="quantity">
												<b-form-input size="sm" v-model="item.quantity" type="number" />
											</b-form-group>
										</b-col>										
									</b-row>
									<b-row>
										<b-col cols="12" lg="4">
											<custom-text-area v-model="payload.comments" :rows="0" />
										</b-col>	
										
										<b-col cols="12" md="8" class="mt-2">
											
											<b-button @click="saveMovements"  size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
											class="mr-1">
												<feather-icon icon="PlusIcon" class="mr-50"/>
												<span>Guardar Movimiento</span>
											</b-button>

											<b-button size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-secondary"
												@click="$router.push({name: 'apps-e-commerce-shop'})">
												<feather-icon icon="CornerUpLeftIcon" class="mr-50" />
												<span> Regresar </span>
											</b-button>
										</b-col>
									</b-row>
								</div>
							</b-card-body>
						</b-card>
					</b-form>
				</b-tab>
			</b-tabs>
		</b-overlay>
	</section>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref } from '@vue/composition-api'
import {
	BCard, BCardBody, BRow, BCol, BImg, BCardText, BLink, BButton, BDropdown, BDropdownItem, BAlert,
	BBadge, BListGroup, BListGroupItem, BCardFooter, BTab, BTabs, BFormFile, BFormGroup,
	BOverlay, BForm, BFormTextarea, BFormInput, BFormSelect, BFormCheckbox, BFormRadio, BFormRadioGroup,
} from 'bootstrap-vue'
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'
import CustomTextArea from '@/@core/components/app-forms/CustomTextArea.vue'
import ECommerceProductDetailsItemFeatures from './ECommerceProductDetailsItemFeatures.vue'
import ECommerceProductDetailsRelatedProducts from './ECommerceProductDetailsRelatedProducts.vue'
import { useEcommerceUi } from '../useEcommerce'
import { heightTransition } from '@core/mixins/ui/transition'

import ArticlesProvider from '@/providers/Articles'
const ArticlesResource = new ArticlesProvider()

export default {
	directives: {
		Ripple,
	},
	mixins: [heightTransition],
	components: {
		// BSV
		BCard,
		BCardBody,
		BRow,
		BCol,
		BImg,
		BCardText,
		BLink,
		BButton,
		BDropdown,
		BDropdownItem,
		BAlert,
		BBadge,
		BListGroup,
		BListGroupItem,
		BCardFooter,
		BTab,
		BTabs,
		BForm,
		BFormFile,
		BFormGroup,
		BOverlay,
		BFormTextarea, 
		BFormInput, 
		BFormSelect, 
		BFormCheckbox, 
		BFormRadio, 
		BFormRadioGroup,

		// Custom
		CustomTextArea,
		vSelect,
		ECommerceProductDetailsItemFeatures,
		ECommerceProductDetailsRelatedProducts,
	},
	data() {
		return {
			logo_1: require('@/assets/images/logo/ferreysa_1.jpg'),
			imageArticle: null,
			isMobile: false,
		}
	},
	computed: {
		canSave() {
			return this.canAccess(44) // Permitir guardar en nivel detalle articulo el movimiento (Ingreso) de inventario fisico
		},
		canEdit() {
			return this.canAccess(43) // Permitir editar el artÃ­culo (solo imagen)
		},
		wareHouses() {
			return store.getters['auth/getWareHouses']
		},
	},
	created() {
		// window.addEventListener('resize', this.initTrHeight)
	},
	destroyed() {
		// window.removeEventListener('resize', this.initTrHeight)
	},
	beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
	mounted() {
		window.addEventListener('resize', this.handleResize);
        this.handleResize()
	},
	methods: {
		async requestProduct(product) {
			const payload = {
				id: 0,
				empresa_Id: 0,
				usuario_Id: "string",
				articulo_Id: product.id,
				articulo: product.name,
				clave_Articulo: product.clabe,
				estatus: "ACTIVO",
				fecha: "2024-10-15T17:43:17.892Z",
				fecha_Alta: "2024-10-15T17:43:17.892Z",
				fecha_Modificacion: "2024-10-15T17:43:17.892Z"
			}
			
			try {
				this.loading = true
				const { data } = await ArticlesResource.requestArticles(payload)
				this.loading = false
				if (data.isSuccesful) {
					this.success('Producto solicitado correctamente')
				} else {
					this.danger(data.message)
				}
			}catch (e) {
				this.loading = false
				this.handleResponseErrors(e)
			}
		},
		handleResize() {
            this.isMobile = window.innerWidth < 998;
        },
		async saveMovements() {
			// console.log(this.payload)

			const payload = {
				docto_Invfis_Id: -1,
				almacen_Id: this.payload.warehouseId,
				sucursal_Id: 0,
				folio: "string",
				fecha: "2024-10-15T22:06:46.720Z",
				cancelado: "N",
				aplicado: "N",
				descripcion: this.payload.comments,
				docto_In_Id_Ent: null,
				docto_In_Id_Sal: null,
				usuario_Creador: "string",
				fecha_Hora_Creacion: "2024-10-15T22:06:46.720Z",
				usuario_Aut_Creacion: "string",
				usuario_Ult_Modif: "string",
				fecha_Hora_Ult_Modif: "2024-10-15T22:06:46.720Z",
				usuario_Aut_Modif: "string",
				usuario_Cancelacion: "string",
				fecha_Hora_Cancelacion: "2024-10-15T22:06:46.720Z",
				usuario_Aut_Cancelacion: "string",
				doctosInvFisDet: this.payload.items.map(i => {
					return {
						docto_Invfis_Det_Id: -1,
						docto_Invfis_Id: -1,
						clave_Articulo: i.key,
						articulo_Id: i.articleId,
						unidades: i.quantity*1,
						docto_In_Det_Id_Ent: null,
						docto_In_Drt_Id_Sal: null,
					}					
				})
			}

			//Validamos si la cantidad es mayor a 0
			const items = payload.doctosInvFisDet.filter(i => i.unidades > 0)
			if (items.length === 0) {
				this.danger('La cantidad debe ser mayor a 0')
				return
			}

			try {
				this.loading = true
				const { data } = await ArticlesResource.addDoctosInvfis(payload)
				console.log(data)
				this.loading = false
				if (data.isSuccesful) {
					this.success('Movimiento guardado correctamente')
					this.$router.push({ name: 'apps-e-commerce-shop' })
				} else {
					this.danger(data.message)
				}
			}catch (e) {
				this.loading = false
				this.handleResponseErrors(e)
			}
		},
		// Método que se ejecuta cuando se selecciona una imagen
		onFileChange(event) {
			this.imageArticle = event.target.files[0]; // Guarda el archivo seleccionado
		},
		async uploadImage() {
			try {
				const formData = new FormData();
				formData.append('imagen', this.imageArticle); // 'image' es el campo que se enviará al backend
				formData.append('articuloId', this.product.id); // ID del artículo
				const { data } = await ArticlesResource.uploadImagenesArticulo(formData);
				this.loading = false;
				if (data.isSuccesful) {

					// Actualizar la imagen en el detalle del producto
					this.product.image_Exists = true;
					this.product.imagen = data.data.imagen;

					//store.commit('app-ecommerce/SET_DETAIL_PRODUCT', this.detail);
					this.isEdit = false;

					this.success(data.message);

				} else {
					this.danger(data.message);
				}
			} catch (e) {
				this.loading = false;
				this.handleResponseErrors(e);
			}
		},
		initTrHeight() {
			this.trSetHeight(null)
			this.$nextTick(() => {
				this.trSetHeight(this.$refs.form.scrollHeight)
			})
		},
	},
	setup() {
		const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

		const loading = ref(false)
		const product = ref(null)
		const isEdit = ref(false);
		const detail = ref(null);
		const payload = ref({
			warehouseId: null,
			comments: '',
			items: [{
				articleId: null,
				article: null,
				key: null,
				quantity: 0,
			}]
		});

		// Remote Data
		const fetchProduct = () => {

			//Obtener el detalle desde localstorage
			const _detail = store.getters['app-ecommerce/getProductDetail']
			product.value = _detail.product //s.find(i => i.id === router.currentRoute.params.slug);
			detail.value = _detail;
			payload.value.items[0].articleId = product.value.id;
			payload.value.items[0].article = product.value.name;
			payload.value.items[0].key = product.value.clabe;
		}

		// UI
		const selectedColor = ref(null)

		fetchProduct()

		return {

			loading,
			// Fetched Product
			product,
			isEdit,
			detail,
			payload,

			// UI
			selectedColor,
			handleCartActionClick,
			toggleProductInWishlist,
		}
	},
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
@import '~@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.invoice-add-wrapper {
	.add-new-client-header {
		padding: $options-padding-y $options-padding-x;
		color: $success;

		&:hover {
			background-color: rgba($success, 0.12);
		}
	}
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
	background-color: $product-details-bg;
}

.form-item-action-col {
	width: 27px;
}

.repeater-form {
	// overflow: hidden;
	transition: .35s height;
}

.v-select {

	&.item-selector-title,
	&.payment-selector {
		background-color: #fff;

		.dark-layout & {
			background-color: unset;
		}
	}
}

.dark-layout {
	.form-item-section {
		background-color: $theme-dark-body-bg;

		.row .border {
			background-color: $theme-dark-card-bg;
		}

	}
}
</style>
