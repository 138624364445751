<template>
    <div>
        <b-form-group :label="label" :description="`${remainingCharacters} caracteres restantes`" label-for="description">
            <b-form-textarea :size="size" 
                id="description"
                v-model="limitedObservaciones"  
                :placeholder="placeholder"
                @input="limitText"
                :maxlength="maxCharacters"
                :readonly="readonly"
                :required="required"
                :rows="rows"
            />
        </b-form-group>
    </div>
</template>

<script>

import {
	BCard, BCardBody, BRow, BCol, BImg, BCardText, BLink, BButton, BDropdown, BDropdownItem, BAlert,
	BBadge, BListGroup, BListGroupItem, BCardFooter, BTab, BTabs, BFormFile, BFormGroup,
	BOverlay, BForm, BFormTextarea, BFormInput, BFormSelect, BFormCheckbox, BFormRadio, BFormRadioGroup,
} from 'bootstrap-vue'

export default {
    name: "custom-text-area",
    components: {
        BCard, BCardBody, BRow, BCol, BImg, BCardText, BLink, BButton, BDropdown, BDropdownItem, BAlert,
        BBadge, BListGroup, BListGroupItem, BCardFooter, BTab, BTabs, BFormFile, BFormGroup,
        BOverlay, BForm, BFormTextarea, BFormInput, BFormSelect, BFormCheckbox, BFormRadio, BFormRadioGroup,
    },
    props: {
        size: {
            type: String,
            default: "md",
        },
        label: {
            type: String,
            required: false,
            default: "Ingresar Comentario (opcional)",
        },
        placeholder: {
            type: String,
            default: "Breve comentario de hasta 190 caracteres.",
        },
        rows: {
            type: Number,
            default: 3,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            default: "",
        },
        maxCharacters: {
            type: Number,
            default: 190,
        },
        value: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            observaciones: this.value,
        };
    },
    computed: {
        limitedObservaciones: {
            get() {
                return this.observaciones;
            },
            set(value) {
                this.observaciones = value.slice(0, this.maxCharacters);
            }
        },
        remainingCharacters() {
           return this.maxCharacters - this.observaciones.length;
        },
    },
    methods: {
        limitText(event) {
            if (this.observaciones.length > this.maxCharacters) {
                this.observaciones = this.observaciones.slice(0, this.maxCharacters);
            }
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(newValue) {
                this.observaciones = newValue;
            }
        },
        observaciones: {
            immediate: true,
            handler(newValue) {
                this.$emit('input', newValue);
            }
        }
    }   
};
</script>